body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
	font-family: "Londrina Outline";
	src: url(./LondrinaOutline.woff2);
}

.all-copy p {
	-webkit-user-select: all; /* Chrome all / Safari all */
	-moz-user-select: all; /* Firefox all */
	-ms-user-select: all; /* IE 10+ */
	user-select: all; /* Likely future */
}
